#app-sidebar {
  position: fixed;
  left: 0;
  height: 100vh;
  background: #3E2718;
  overflow: auto;
  overflow-x: hidden;
  z-index: 2;
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    border-bottom: 1px solid #6D452A;
    img {
      max-width: 100%;
    }
  }
  .ant-layout-sider, .ant-menu {
    border-right: none;
    background: #3E2718;
  }
  .ant-menu-item {
    width: 100%;
    color: #dbb579;

    a {
      color: #dbb579;
    }
    &:hover,
    &:hover a {
      color: #f00
    }

    &:after {
      display: none;
    }
  }
  .ant-menu-submenu {
    .ant-menu {
      background: #2E1D12;
    }
    .ant-menu-submenu-title {
      color: #dbb579;
      &:hover {
        color: #f00;
      }
      &:hover .ant-menu-submenu-arrow:before,
      &:hover .ant-menu-submenu-arrow:after {
        background: #f00;
      }
    }
    .ant-menu-submenu-arrow:before,
    .ant-menu-submenu-arrow:after {
      background: #dbb579;
    }
  }
  .ant-menu-item-selected {
    background: #7f5032;
    color: #f00;
    a {
      color: #f00;
    }
  }
}
