/* reset样式 */
/*,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video*/
html,body,div,span,applet,object,h1,h2,h3,h4,h5,h6,p {
	margin:0;
	padding:0;
	border:0;
	font-size:100%;
	font:inherit;
	font-weight:normal;
  vertical-align:baseline;
  box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section {
	display:block;
}
ol,ul,li {
	list-style:none;
}
blockquote,q {
	quotes:none;
}
blockquote:before,blockquote:after,q:before,q:after {
	content:'';
	content:none;
}
table {
	border-collapse:collapse;
	border-spacing:0;
}
th,td {
	vertical-align:middle;
}
a {
	outline:none;
	text-decoration:none;
	-webkit-backface-visibility:hidden;
}
a:focus {
	outline:none;
}
input:focus,select:focus,textarea:focus {
	outline:-webkit-focus-ring-color auto 0;
}
img {
  max-width: 100%;
  vertical-align: middle;
}
html, body {
  min-height: 100%;
  font-size: 14px;
  background: #f0f2f5;
  color: #333;
  font-family: Arial, Helvetica, sans-serif;
}

/* 自定义样式 */
.ant-menu-submenu-arrow {
  color: #fff;
}
.app-mask {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.app-content {
  min-height: calc(100vh - 100px);
  margin: 20px;
  padding: 20px;
  background: #fff;
  overflow: auto;
}
@media screen and(max-width: 768px) {
  .app-main {
    margin-left: 80px !important;
    .small-screen-collapse {
      display: block !important;
    }
    .big-screen-collapse {
      display: none !important;
    }
  }
  .app-mask {
    display: block;
  }
}


